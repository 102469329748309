import {
  impactAccountsActions,
  impactAccountsMutations,
  impactAccountsGetters
} from './types'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import { ACCOUNTS_PAGE_LIMIT } from '../constants'
import { createAccountFormSchema } from '../form-schemas/create-account'

const loneSdkCall = new LoneSdkCall()

export default {
  async [impactAccountsActions.LOAD_LIST] ({ commit }, opts = {}) {
    commit(impactAccountsMutations.SET_IS_LOADING, true)
    commit(impactAccountsMutations.SET_LIST_OPTS, opts)

    const { data } = await loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getAccounts({
        query: createAccountsQuery(opts)
      })
    )

    commit(impactAccountsMutations.SET_LIST, data)
    commit(impactAccountsMutations.SET_PAGE_NUMBER, 0)

    commit(impactAccountsMutations.SET_IS_LOADING, false)
  },

  async [impactAccountsActions.LOAD_MORE] ({ commit, getters }) {
    if (getters[impactAccountsGetters.PAGE_NUMBER] === null) return

    commit(impactAccountsMutations.SET_IS_NEXT_LOADING, true)

    const pageNumber = getters[impactAccountsGetters.PAGE_NUMBER] + 1
    const { data } = await loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getAccounts({
        query: createAccountsQuery({
          ...getters[impactAccountsGetters.LIST_OPTS],
          page: { number: pageNumber }
        })
      })
    )

    commit(impactAccountsMutations.PUSH_LIST, data)
    commit(
      impactAccountsMutations.SET_PAGE_NUMBER,
      data.length < ACCOUNTS_PAGE_LIMIT ? null : pageNumber
    )

    commit(impactAccountsMutations.SET_IS_NEXT_LOADING, false)
  },

  async [impactAccountsActions.UPDATE_IS_LOGIN_ALLOWED] (
    { commit },
    { id, value }
  ) {
    await sdk.impactAutoBackOffice.updateAccount({
      accountId: id,
      attributes: { isLoginAllowed: value }
    })
    commit(impactAccountsMutations.UPDATE_IS_LOGIN_ALLOWED, { id, value })
  },

  async [impactAccountsActions.UPDATE_PASSWORD] ({ commit }, { id, value }) {
    await sdk.impactAutoBackOffice.updateAccount({
      accountId: id,
      attributes: {
        password: value,
        isConnectorAvailable: true,
        disablingReason: ''
      }
    })

    commit(impactAccountsMutations.UPDATE_CREDENTIALS, id)
  },

  async [impactAccountsActions.UPDATE_BID_LIMIT] (
    { commit },
    { id, value }
  ) {
    await sdk.impactAutoBackOffice.updateAccount({
      accountId: id,
      attributes: { bidLimit: Number(value).toFixed(2) }
    })

    commit(impactAccountsMutations.UPDATE_BID_LIMIT, { id, value })
  },

  async [impactAccountsActions.CREATE_ACCOUNT] ({ commit }) {
    const form = createAccountFormSchema.form
    const account = {
      id: form.login,
      bidLimit: Number(form.bidLimit).toFixed(2),
      country: form.country,
      feeLevel: form.feeLevel,
      disablingReason: '',
      isConnectorAvailable: true,
      isLoginAllowed: true,
      relationships: {
        accountStats: {
          identitiesCount: 0,
          winnersCount: 0
        }
      }
    }

    await sdk.impactAutoBackOffice.createAccount({
      accountId: account.id,
      attributes: {
        bidLimit: account.bidLimit,
        country: account.country,
        feeLevel: account.feeLevel,
        isLoginAllowed: account.isLoginAllowed,
        password: form.password,
        isParsing: true,
      }
    })

    commit(impactAccountsMutations.UNSHIFT_LIST, account)
  }
}

function createAccountsQuery (opts) {
  return {
    ...opts,
    page: {
      ...(opts.page || {}),
      limit: ACCOUNTS_PAGE_LIMIT
    },
    include: ['account_stats']
  }
}
