import { impactAccountsGetters } from './types'
import { ExtensionAccount } from 'Models/ExtensionAccount'

export default {
  [impactAccountsGetters.IS_LOADING]: state => state.isLoading,
  [impactAccountsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [impactAccountsGetters.LIST]: state => state.list
    .map(item => new ExtensionAccount(item)),
  [impactAccountsGetters.LIST_RAW]: state => state.list,
  [impactAccountsGetters.LIST_OPTS]: state => state.listOpts,

  [impactAccountsGetters.PAGE_NUMBER]: state => state.pageNumber,
}
