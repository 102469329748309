export function genAccountsState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    listOpts: {},
    pageNumber: 0,
  }
}

export default genAccountsState
